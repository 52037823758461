'use client';
import dynamic from 'next/dynamic';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import BackendService from '@/services/backendService';
import { configureConfig } from '@/config';
import { AnalyticsProvider } from '@/providers/AnalyticsProvider';

const GameButler = dynamic(() => import('../components/GameButler'), {
  ssr: false,
});

const App = () => {
  useBeforeUnload();
  const { config } = configureConfig();
  useEffect(() => {
    const LocalstorageService = require('@/LocalstorageService').default;
    const IndexedDBStorageService =
      require('@/IndexedDBStorageService').default;

    // Remove any old curipod localstorage cache
    const clearStaleCache = async () => {
      LocalstorageService.clearStaleCache();
      await IndexedDBStorageService.clearStaleCache();
    };
    clearStaleCache();
  }, []);

  useState(() => {
    const bService = new BackendService();
    bService.init(config.backend.coreApiUrl);

    return bService;
  });
  const [queryClient] = useState(() => new QueryClient());

  return (
    <AnalyticsProvider>
      <QueryClientProvider client={queryClient}>
        <AppContainer>
          <GameButler />
        </AppContainer>
      </QueryClientProvider>
    </AnalyticsProvider>
  );
};

const AppContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export default App;
